<template>
  <div>
    <BaseLoader :class="loaderClass" />
    <div
      class="-no-results"
      :class="{ '-is-show': foundNothing || !isHitsAvailable }"
    >
      <BaseIcon viewBox="0 0 145 144" icon-name="pic-search" />
    </div>
    <div class="search-shortened-content-wrapper">
      <SearchHits />
    </div>
  </div>
</template>

<script>
  import BaseIcon from '@/components/BaseIcon.vue';
  import BaseLoader from '@/components/BaseLoader2.vue';
  import SearchHits from '@/containers/searchhits/SearchHits.vue';
  import { mapState, mapGetters } from 'vuex';

  const LOADER_DELAY = 2000;
  let showLoaderTimeout;

  export default {
    name: 'HitsContent',
    components: {
      BaseIcon,
      SearchHits,
      BaseLoader,
    },
    data() {
      return {
        isShowLoader: false,
      };
    },
    computed: {
      ...mapState('SwSearchStore', [
        'foundNothing',
        'searchInProcess',
        'searchText',
      ]),
      ...mapGetters('SwSearchStore', ['isSearchStateFull']),
      ...mapGetters('SwHitsStore', ['isHitsAvailable']),
      loaderShow() {
        return (
          !this.isSearchStateFull && !this.foundNothing && this.isShowLoader
        );
      },
      loaderClass() {
        return {
          '-is-show': this.loaderShow,
        };
      },
    },
    watch: {
      searchText() {
        clearTimeout(showLoaderTimeout);
        this.isShowLoader = false;
        showLoaderTimeout = setTimeout(() => {
          this.isShowLoader = true;
        }, LOADER_DELAY);
      },
    },
  };
</script>

<style lang="less">
  .hits-block {
    .dot-loader {
      position: absolute;
      top: 48px;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 1;
      background: #fff;
      pointer-events: none;
      opacity: 0;

      &.-is-show {
        opacity: 1;
        transition: opacity 0.1s cubic-bezier(0, 1, 0, 1);
      }
    }
  }
</style>
