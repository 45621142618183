<template>
  <div
    :class="{
      'is-popup-displayed': mainPopupOpened,
      'rtl-lang': isTextRightSided,
      'dev-env': isDev,
      'brand-ocean': brand === 'ocean',
      'simple-view': isSimpleView,
    }"
    :dir="getDirection"
  >
    <MediaDetector v-if="mainPopupOpened" />
    <div v-if="!spriteUrl" class="icons-sprite">
      <BaseIconsSprite />
    </div>

    <div id="nav" />

    <slot />
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import config from '@/configs/default.config.json';

  import provider from './provider';

  import MediaDetector from '@/containers/mediadetector/MediaDetector.vue';

  import EventEnum from '@/enums/EventEnum';
  import offlineSearchService from '@/services/OfflineSearch/offlineSearch/OfflineSearchService';
  import FullTextSearchService from '@/services/Search/FullTextSearchService';

  import * as log from 'loglevel';
  log.setLevel('error');

  export default {
    name: 'SearchWidgetComponent',
    config,
    components: {
      BaseIconsSprite() {
        return import('@/components/BaseIconsSprite.vue');
      },
      MediaDetector,
    },
    props: {
      profile: {
        type: String,
        default: config.parameters.brand,
      },
      isExtension: {
        type: Boolean,
        default: false,
      },
      language: {
        type: String,
        default: config.defaultLanguage,
      },
      interfaceLanguage: {
        type: String,
        default: config.defaultLanguage,
      },
      environment: {
        type: String,
        default: config.environments.development,
      },
      provider: {
        type: Object,
        default: () => {
          return provider;
        },
      },
      manager: {
        type: Object,
      },
      viewMode: {
        type: String,
        default: config.viewModes.default,
      },
    },
    computed: {
      ...mapState('SwHitsStore', ['activeHit']),
      ...mapState('SwContextStore', [
        'env',
        'brand',
        'parameters',
        'lang',
        'spriteUrl',
        'mainPopupOpened',
      ]),
      ...mapState('SwSearchStore', ['parsedQuery']),
      isSimpleView() {
        return this.$store.getters['SwContextStore/isSimpleView'];
      },
      isDev() {
        return this.env === config.environments.development;
      },
      getDirection() {
        return config.rtlLangs.indexOf(this.lang) !== -1 ? 'rtl' : 'ltr';
      },
      isTextRightSided() {
        return config.rtlLangs.indexOf(this.lang) !== -1;
      },
      isParametersInitiated() {
        return this.$store.getters['SwContextStore/isParametersInitiated'];
      },
      isOnline() {
        return this.$store.getters['SwOfflineModeStore/getIsOnline'];
      },
    },
    watch: {
      profile: {
        immediate: true,
        handler(newVal, oldVal) {
          if (newVal !== oldVal) {
            this.$store.dispatch('SwContextStore/setBrand', this.profile);
          }
        },
      },
      isExtension: {
        immediate: true,
        handler(newVal, oldVal) {
          if (newVal !== oldVal) {
            this.$store.dispatch(
              'SwContextStore/setIsExtension',
              this.isExtension
            );
          }
        },
      },
      language: {
        immediate: true,
        async handler(newVal, oldVal) {
          try {
            const lang = this.$store.getters['SwContextStore/getLang'];
            if (newVal !== oldVal && newVal !== lang) {
              const newLang = this.getLanguage();
              this.$store.dispatch('SwContextStore/setLang', newLang);
              await this.$store.dispatch(
                'SwFilterStore/performFilterSearchIfNeeded'
              );
            }
          } catch (error) {
            log.error(
              `Handler for language watcher failed with error: ${error}`
            );
          }
        },
      },
      interfaceLanguage: {
        immediate: true,
        handler(newVal, oldVal) {
          if (
            newVal !== oldVal &&
            config.interfaceLanguages.indexOf(newVal) !== -1
          ) {
            this.$i18n.locale = newVal;
          }
        },
      },
      environment: {
        immediate: true,
        handler(newVal, oldVal) {
          if (newVal !== oldVal) {
            this.$store.dispatch('SwContextStore/setEnv', this.environment);
          }
        },
      },
      provider: {
        immediate: true,
        handler(newVal, oldValue) {
          if (newVal !== oldValue) {
            Object.assign(provider, newVal);
          }
        },
      },
      activeHit: {
        immediate: true,
        handler(newDoc, oldDoc) {
          if (newDoc !== oldDoc && typeof newDoc === 'object') {
            if (newDoc.bookMeta?.urlRules?.type === 'library') {
              this.setActiveHitCoverPath(newDoc.publicationId);
            }
          }
        },
      },
      viewMode: {
        immediate: true,
        handler(viewMode) {
          this.$store.dispatch('SwContextStore/setViewMode', viewMode);
        },
      },
      'manager.getDocByDocId'(params) {
        if (params.docId) {
          this.$_getDocByDocId(params.docId);
        }
      },
      'manager.isPartialAccess'(isPartialAccess) {
        this.$_setPartialAccess(isPartialAccess);
      },
      'manager.openedPublicationId'(openedPublicationId) {
        this.$_setOpenedPublicationId(openedPublicationId);
      },
      isOnline(currIsOnline, prevIsOnline) {
        if (
          currIsOnline &&
          !this.isParametersInitiated &&
          prevIsOnline !== null
        ) {
          this.init();
        }
      },
    },
    mounted() {
      if ((this.manager || {}).hasOwnProperty('openPopup')) {
        this.$store.dispatch(
          'SwContextStore/setMainPopupOpened',
          this.manager.openPopup
        );
      }

      window.addEventListener('online', this.handleNetworkChangeEvent);
      window.addEventListener('offline', this.handleNetworkChangeEvent);

      this.$el.focus();
    },
    async created() {
      await this.init();
    },
    beforeDestroy() {
      window.removeEventListener('online', this.handleNetworkChangeEvent);
      window.removeEventListener('offline', this.handleNetworkChangeEvent);
    },
    methods: {
      async init() {
        try {
          if (process.env.NODE_ENV === config.environments.development) {
            this.$store.dispatch('SwContextStore/setMainPopupOpened', true);
          }
          offlineSearchService.updateOnlineStatus(this.$store);
          this.$store.dispatch('SwContextStore/setEnv', this.environment);
          await this.$store.dispatch(
            'SwContextStore/initRemoteConfigParameters',
            this.env
          );
          this.$store.dispatch('SwContextStore/initConfigParameters');
          this.$store.dispatch('SwContextStore/setBrand', this.profile);
          this.$store.dispatch('SwContextStore/setLang', this.getLanguage());
          await this.$store.dispatch('SwContextStore/initSearch', this.$store);
          this.$store.dispatch('SwFilterStore/removeLastUserFilter');
          this.customFontsUpload();
          await this.$store.dispatch(
            'SwFilterStore/performFilterSearchIfNeeded'
          );
        } catch (error) {
          log.error(`[search widget]: init failed with ${error}`);
        }
      },
      $_getDocByDocId(docId) {
        FullTextSearchService.getDocById({ docId, language: this.lang }).then(
          data => {
            this.$emit('searchWidgetEvent', {
              type: EventEnum.GET_DOC_BY_DOC_ID,
              data: {
                publication: data,
                query: this.parsedQuery,
              },
            });
          }
        );
      },

      $_setPartialAccess(isPartialAccess) {
        this.$store.dispatch(
          'SwContextStore/setPartialAccess',
          isPartialAccess
        );
      },

      $_setOpenedPublicationId(openedPublicationId) {
        this.$store.dispatch(
          'SwContextStore/setOpenedPublicationId',
          openedPublicationId
        );
      },

      getLanguage() {
        if (!config.defaultLanguages[this.language]) {
          return config.defaultLanguage;
        }
        return this.language;
      },

      customFontsUpload() {
        const fonts = ['Amiri'];
        const externalAppHost = this.parameters.appHost || './';

        fonts.forEach(fontName => {
          const style = document.createElement('style');
          style.innerHTML =
            '@font-face {' +
            'font-family: ' +
            fontName +
            '; ' +
            'src: url("' +
            externalAppHost +
            'assets/fonts/' +
            fontName +
            '.woff2") format("woff2");' +
            '}';
          const ref = document.head.querySelector('script');
          document.head.insertBefore(style, ref);
        });
      },

      async setActiveHitCoverPath(publicationId) {
        try {
          const coverPath = await provider.getCoverPath(publicationId);
          if (coverPath) {
            //TODO: Vue.set will b unavailable in Vue 3
            // Vue.set(this.activeHit, 'coverPath', coverPath);
          }
        } catch (error) {
          log.warn(
            `Cover path was not provided via provider. Failed with ${error}`
          );
        }
      },

      handleNetworkChangeEvent() {
        offlineSearchService.updateOnlineStatus(this.$store);
      },
    },
  };
</script>

<style src="./SearchWidget.less" lang="less"></style>
