<template>
  <div>
    <BaseLoader :class="loaderClass" />
    <div
      class="-no-results"
      dir="ltr"
      :class="{ '-is-show': !loaderShow && (foundNothing || !isHitsAvailable) }"
    >
      <BaseIcon viewBox="0 0 145 144" icon-name="pic-search" />
      <div v-if="foundNothing" class="no-results message">
        <div
          class="message-title"
          v-html="$tc('BottomBar.NoResultsFound.bigLabel')"
        />
        <div
          class="message-description"
          v-html="$tc('BottomBar.NoResultsFound.smallLabel')"
        />
      </div>
      <div v-if="searchInProcess" class="searching message">
        <div
          class="message-title"
          v-html="$tc('BottomBar.Searching.bigLabel')"
        />
        <div
          class="message-description"
          v-html="$tc('BottomBar.Searching.smallLabel')"
        />
      </div>
      <div>
        <div
          v-if="!searchInProcess && !foundNothing"
          class="no-request message"
        >
          <div
            class="message-title"
            v-html="$tc('BottomBar.NoRequest.bigLabel')"
          />
          <div
            class="message-description"
            v-html="$tc('BottomBar.NoRequest.smallLabel')"
          />
        </div>
      </div>
    </div>
    <div class="search-shortened-content-wrapper">
      <SearchHits />
    </div>
  </div>
</template>

<script>
  import BaseIcon from '@/components/BaseIcon.vue';
  import BaseLoader from '@/components/BaseLoader2.vue';
  import SearchHits from '@/containers/searchhits/SearchHits.vue';
  import { mapState, mapGetters } from 'vuex';

  const LOADER_DELAY = 1000;
  let showLoaderTimeout;

  export default {
    name: 'HitsContent',
    components: {
      BaseIcon,
      SearchHits,
      BaseLoader,
    },
    data() {
      return {
        isShowLoader: false,
      };
    },
    computed: {
      ...mapState('SwSearchStore', [
        'foundNothing',
        'searchInProcess',
        'searchText',
      ]),
      ...mapGetters('SwSearchStore', ['isSearchStateFull']),
      ...mapGetters('SwHitsStore', ['isHitsAvailable']),
      loaderShow() {
        return (
          !this.isSearchStateFull &&
          !this.foundNothing &&
          !this.isHitsAvailable &&
          this.isShowLoader
        );
      },
      loaderClass() {
        return {
          '-is-show': this.loaderShow,
        };
      },
    },
    watch: {
      searchText() {
        this.handleSearchLoader();
      },
    },
    mounted() {
      this.handleSearchLoader();
    },
    methods: {
      handleSearchLoader() {
        if (this.searchText?.trim()?.length < 3) {
          this.isShowLoader = false;
          return;
        }
        clearTimeout(showLoaderTimeout);
        this.isShowLoader = false;
        showLoaderTimeout = setTimeout(() => {
          this.isShowLoader = this.searchText?.trim()?.length >= 3;
        }, LOADER_DELAY);
      },
    },
  };
</script>
<style lang="less">
  .hits-block {
    .dot-loader {
      position: absolute;
      top: 48px;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 1;
      background: #fff;
      pointer-events: none;
      display: none;
      opacity: 0;

      &.-is-show {
        display: flex;
        opacity: 1;
        transition: opacity 0.1s cubic-bezier(0, 1, 0, 1);
      }
    }
  }
</style>
