<template>
  <div class="expanded-content">
    <BaseLoader :class="loaderClass" />
    <div
      class="-no-results"
      dir="ltr"
      :class="{ '-is-show': !loaderShow && (foundNothing || !isHitsAvailable) }"
    >
      <BaseIcon viewBox="0 0 145 144" icon-name="pic-book" />
      <div v-if="foundNothing" class="no-results message">
        <div
          class="message-title"
          v-html="$tc('BottomBar.NoResultsFound.bigLabel')"
        />
        <div
          class="message-description"
          v-html="$tc('BottomBar.NoResultsFound.smallLabel')"
        />
      </div>
      <div v-if="searchInProcess" class="searching message">
        <div
          class="message-title"
          v-html="$tc('BottomBar.Searching.bigLabel')"
        />
        <div
          class="message-description"
          v-html="$tc('BottomBar.Searching.smallLabel')"
        />
      </div>
      <div>
        <div
          v-if="!searchInProcess && !foundNothing"
          class="no-request message"
        >
          <div
            class="message-title"
            v-html="$tc('BottomBar.NoRequest.bigLabel')"
          />
          <div
            class="message-description"
            v-html="$tc('BottomBar.NoRequest.smallLabel')"
          />
        </div>
      </div>
    </div>
    <div class="search-detailed-content-wrapper">
      <div class="regular-content-wrapper">
        <BookMeta />
        <Paragraphs class="search-result-item-content" />
        <div v-if="isCopyrightVisible" class="copyright-block">
          {{ $tc('CopyRight.label') }}
        </div>
        <div
          v-if="!isCopyrightVisible"
          class="fab-wrapper"
          @click="openPublication"
        >
          <BaseIcon viewBox="0 0 24 24" icon-name="ico-book-new" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';

  import BaseIcon from '@/components/BaseIcon.vue';
  import BaseLoader from '@/components/BaseLoader2.vue';
  import BookMeta from '@/containers/bookmeta/BookMeta.vue';
  import Paragraphs from '@/containers/paragraphs/Paragraphs.vue';

  import PublicationVisibilityStatuses from '@/enums/PublicationVisibilityStatusesEnum.js';

  const LOADER_DELAY = 1000;
  let showLoaderTimeout;

  export default {
    name: 'ResultsContent',
    components: {
      BaseIcon,
      BaseLoader,
      BookMeta,
      Paragraphs,
    },
    data() {
      return {
        isShowLoader: false,
      };
    },
    computed: {
      ...mapState('SwHitsStore', ['activeHit']),
      ...mapState('SwSearchStore', [
        'foundNothing',
        'searchInProcess',
        'searchText',
      ]),
      ...mapGetters('SwSearchStore', ['isSearchStateFull']),
      ...mapGetters('SwHitsStore', ['isHitsAvailable']),

      isCopyrightVisible() {
        return (
          this.activeHit?.bookMeta?.accessStatus ===
          PublicationVisibilityStatuses.ENCUMBERED
        );
      },
      loaderShow() {
        return (
          !this.isSearchStateFull &&
          !this.foundNothing &&
          !this.isHitsAvailable &&
          this.isShowLoader
        );
      },
      loaderClass() {
        return {
          '-is-show': this.loaderShow,
        };
      },
    },
    watch: {
      searchText() {
        this.handleSearchLoader();
      },
    },
    mounted() {
      this.handleSearchLoader();
    },
    methods: {
      openPublication(event) {
        if (event) {
          event.stopPropagation();
        }
        this.$store.dispatch(
          'SwPublicationsStore/openPublication',
          this.activeHit
        );
      },
      handleSearchLoader() {
        if (this.searchText?.trim()?.length < 3) {
          this.isShowLoader = false;
          return;
        }
        clearTimeout(showLoaderTimeout);
        this.isShowLoader = false;
        showLoaderTimeout = setTimeout(() => {
          this.isShowLoader = this.searchText?.trim()?.length >= 3;
        }, LOADER_DELAY);
      },
    },
  };
</script>

<style lang="less" scoped>
  @import '../../assets/styles/variables.less';

  .expanded-content {
    .fab-wrapper {
      position: absolute;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: var(--sw-primary-color);
      right: 16px;
      bottom: 12px;
      transition: all 0.25s ease;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      @media @big, @sw-normal {
        display: none;
      }

      &:hover {
        box-shadow: inset 0 0 0 3px var(--sw-primary-color),
          inset 0 0 15px rgba(255, 255, 255, 0.35),
          0 0 3px var(--sw-primary-color);
      }

      svg {
        fill: white;
      }
    }

    .dot-loader {
      position: absolute;
      top: 48px;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 1;
      background: #fff;
      pointer-events: none;
      display: none;
      opacity: 0;

      &.-is-show {
        display: flex;
        opacity: 1;
        transition: opacity 0.1s cubic-bezier(0, 1, 0, 1);
      }
    }
  }
</style>
