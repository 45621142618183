<template>
  <div class="dot-loader">
    <div>
      <div v-for="n in 4" :key="n" class="dot-item"></div>
    </div>
    <div class="searching message" dir="ltr">
      <div class="message-title" v-html="$tc('BottomBar.Searching.bigLabel')" />
      <div
        class="message-description"
        v-html="$tc('BottomBar.Searching.smallLabel')"
      />
    </div>
  </div>
</template>
<script>
  export default {
    name: 'BaseLoader2',
  };
</script>

<style lang="less">
  .dot-loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    .message {
      .message-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -4%;
        text-align: center;
        margin-top: 132px;
      }

      .message-description {
        font-size: 18px;
        line-height: 26px;
        margin-top: 8px;
      }
    }
  }

  .dot-item {
    position: relative;
    box-sizing: border-box;
    float: left;
    margin: 0 10px 10px 0;
    width: 12px;
    height: 12px;
    border-radius: 3px;
    background: var(--sw-primary-color);

    &:nth-child(4n + 1) {
      animation: wave_23 2s ease 0s infinite;
    }

    &:nth-child(4n + 2) {
      animation: wave_23 2s ease 0.2s infinite;
    }

    &:nth-child(4n + 3) {
      animation: wave_23 2s ease 0.4s infinite;
    }

    &:nth-child(4n + 4) {
      animation: wave_23 2s ease 0.6s infinite;
      margin-right: 0;
    }
  }

  @keyframes wave_23 {
    0% {
      top: 0;
      opacity: 1;
    }

    50% {
      top: 30px;
      opacity: 0.2;
    }

    100% {
      top: 0;
      opacity: 1;
    }
  }
</style>
