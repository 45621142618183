import axios from 'axios';
import parseResponse from '../../services/ElasticSearch/ParseResponse';
import request from '../../services/ElasticSearch/Request';

const CORRECTION_PARA_GAPS = 1.5;

import * as log from 'loglevel';
import RequestEnum from '@/enums/RequestEnum';
import { queryBuilder } from 'oceanlibrary-es-query-builder/es6';
import { INNER_HITS_COUNT } from '../../constants/constants';

log.setLevel('info');

/**
 * NOTE: Everything in this file deals with search, so most of these functions
 * are called whenever a search is executed. That happens in the function
 * src/store/modules/SearchStore.js: actions.performSearch(). It can be traced
 * to the following callers:
  - src/containers/languages/Languages.vue: methods.changeSearchLang()
    (called whenever a language li is clicked)
  - src/containers/page/Page.vue: created()
    (called whenever the page component is created)
  - src/containers/searchField.vue: methods.handleBaseInputEvent()
    - src/components/BaseInput.vue: watch.esInfoMap
      set whenever the ElasticSearch Info Map (i.e. the index names) is updated
    - src/components/BaseInput.vue: methods.$_emitBaseInputEvent()
      - BaseInput.vue: watch.searchInputValue()
        (called whenever the search input value changes)
      - BaseInput.vue: watch.virtualKeyboardInput()
        (called whenever the searchText changes, owing to virtual keyboard input)
      - BaseInput.vue: mounted()
        (called whenever the input is mounted)
      - BaseInput.vue: methods.handleInput()
        (called whenever text is entered into the search input)
 *
 * These are fired whenever the user takes the following actions:
 *
 * - opens the popup
 * - enters text into the search input
 * - pastes text into the search input
 * - uses the virtual keyboard to change the search input
 */

/**
 * Queries that are made for paragraphs or other such must have the highlights added.
 * @param {Object} query The ElasticSearch query object to which the highlight should be added.
 * @param {Object} mainQuery The main ElasticSearch query object for the search phrase, which will always have a highlight property.
 * @returns {Object} The query object with the highlight added.
 */
const _addHighlight = (query, mainQuery) => {
  query.highlight = mainQuery.highlight;
  if (query.collapse) {
    query.collapse.inner_hits.highlight = mainQuery.highlight;
  }
  return query;
};

/**
 * Creates a query for retriving paragraph from docIds.
 *
 * @param {string[]} docIds
 * @returns {Object} The query object for retrieving paragraphs.
 *
 * This helper is used by the following functions below:
 *
 * - getParagraphForEncumbered()
 * - getDocById()
 */
const _createExactParagraphQuery = docIds => ({
  size: 5000,
  query: {
    function_score: {
      query: {
        ids: {
          values: docIds,
        },
      },
      script_score: {
        script: {
          lang: 'painless',
          params: {
            ids: docIds,
          },
          inline: "return 1000 - params.ids.indexOf(doc['docId'].value)",
        },
      },
    },
  },
});

/**
 * Retrieves paragraph content for encumbered (restricted access) text.
 *
 * @param {Object} params - The parameters for the request.
 * @param {Array} params.moreTextIds - IDs of the text to retrieve.
 * @param {string} params.language - Language of the content.
 * @param {Object} params.highlight - Highlight options.
 * @param {number} params.paragraphsCount - Number of paragraphs to retrieve.
 * @returns {Promise} Promise resolving to paragraph data.
 *
 * Trace:
  - src/services/Search/FullTextSearchService.js: getParagraphForEncumbered()
    - src/store/modules/ParagraphsStore.js: getParagraphsForEncumbered()
      - ParagraphsStore.js: actions.getParagraphs()
        - ParagraphsStore.js: actions.performParagraphsSearch()
          - src/store/modules/HitsStore.js: actions.setActiveHit()
            - HitsStore.js: actions.fillHitsStore()
            - HitsStore.js: actions.resetHitsStore()
            - src/containers/searchhits/SearchHits.vue: methods.$_setActiveHit()
              - SearchHits.vue: watch.mainPopupOpened.handler()
                (called as soon as the Popup component is opened)
              - SearchHits.vue: methods.$_processActivateHit()
                - SearchHits.vue: methods.handleHitItemEvent()
                  - src/containers/searchhits/SearchHitItem.vue: methods.activateHit()
                    (called on SearchHitItem click event)
          - src/store/modules/HitsStore.js: actions.setActiveHitById()
            - src/containers/searchhits/SearchHits.vue: methods.$_setActiveHitById()
              (never called)
            - src/store/modules/NavigationStore.js: actions.setHitByNavigationIndex()
              - src/containers/navigation/Navigation.vue: methods.$_navigate()
                (never called)
              - src/store/modules/SearchStore.js: actions.performSearch()
                (see note above)
 *
 * This function can be traced back to whenever the "performParagraphSearch" action is dispatched.
 * Its purpose is to retrieve the initial paragraph content for the "Content" tab.
 * This particular function is only called when the active hit is an encumbered text.
 * It may be called whenever a search is executed, or when the user:
 *
 * - clicks on a SearchHitItem
 */
function getParagraphForEncumbered({
  moreTextIds,
  language,
  highlight, // this is actually the parsedQuery object with the mainQuery
  paragraphsCount,
}) {
  const moreTextQuery = {
    ..._createExactParagraphQuery(moreTextIds),
    size: paragraphsCount,
  };
  if (highlight) {
    _addHighlight(moreTextQuery, highlight.mainQuery);
  }
  const reqOptions = {
    nonCanceledRequest: true,
    requestType: RequestEnum.REQUEST_FOR_CONTENT_INDEXES,
  };

  return request
    .searchReq(moreTextQuery, language, reqOptions)
    .then(_rawDocs => {
      if (!_rawDocs) {
        return;
      }
      const paraMap = parseResponse.parseParagraphsToMapWithoutParaId({
        response: _rawDocs,
      });

      return _createParaStructureFromParaMap(paraMap);
    })
    .catch(error => {
      error.message += ` (getParagraph)`;
      throw error;
    });
}

/**
 * Creates a structured paragraph object from a paragraph map.
 *
 * @param {Object} paraMap - Map of paragraphs.
 * @returns {Object} Structured paragraph data.
 * @private
 */
function _createParaStructureFromParaMap(paraMap) {
  const paraIds = Object.keys(paraMap);
  paraIds.sort((paraIdA, paraIdB) => {
    return _getParaNum(paraIdA) - _getParaNum(paraIdB);
  });

  return {
    sortedIds: paraIds,
    paraMap: paraMap,
  };
}

/**
 * Extracts the paragraph number from a paragraph ID.
 *
 * @param {string} paraId - Paragraph ID.
 * @returns {number} Paragraph number.
 * @private
 */
function _getParaNum(paraId) {
  return parseInt(paraId.split('_')[1], 10);
}

/**
 * Retrieves paragraphs around a specific paragraph.
 *
 * @param {Object} params - Parameters for the request.
 * @param {string} params.publicationId - ID of the publication.
 * @param {string} params.paraId - ID of the current paragraph.
 * @param {number} params.paragraphsCount - Number of paragraphs to retrieve.
 * @param {string} params.language - Language of the content.
 * @param {Object} params.highlight - Highlight options.
 * @param {string} params.accessStatus - Access status of the content.
 * @param {string} params.searchText - Text to search for.
 * @returns {Promise} Promise resolving to paragraph data.
 *
 * Trace:
  - src/services/Search/FullTextSearchService.js: getParagraphs()
    - src/store/modules/ParagraphsStore.js: getParagraphs()
      - ParagraphsStore.js: actions.getParagraphs()
        - ParagraphsStore.js: actions.performParagraphsSearch()
          - src/store/modules/HitsStore.js: actions.setActiveHit()
            - HitsStore.js: actions.fillHitsStore()
            - HitsStore.js: actions.resetHitsStore()
            - src/containers/searchhits/SearchHits.vue: methods.$_setActiveHit()
              - SearchHits.vue: watch.mainPopupOpened.handler()
                (called as soon as the Popup component is opened)
              - SearchHits.vue: methods.$_processActivateHit()
                - SearchHits.vue: methods.handleHitItemEvent()
                  - src/containers/searchhits/SearchHitItem.vue: methods.activateHit()
                    (called on SearchHitItem click event)
          - src/store/modules/HitsStore.js: actions.setActiveHitById()
            - src/containers/searchhits/SearchHits.vue: methods.$_setActiveHitById()
              (never called)
            - src/store/modules/NavigationStore.js: actions.setHitByNavigationIndex()
              - src/containers/navigation/Navigation.vue: methods.$_navigate()
                (never called)
              - src/store/modules/SearchStore.js: actions.performSearch()
                (see note above)
 *
 * This function can be traced back to whenever the "performParagraphSearch" action is dispatched.
 * Its purpose is to retrieve the initial paragraph content for the "Content" tab.
 * This particular function is only called when the active hit is NOT an encumbered text.
 * It may be called whenever a search is executed, or when the user:
 *
 * - clicks a SearchHitItem
 */
function getParagraphs({
  publicationId,
  paraId,
  paragraphsCount,
  language,
  highlight,
  accessStatus,
  searchText,
}) {
  const nextOptions = {
    highlight: highlight,
    includeCurrentPara: true,
    accessStatus: accessStatus,
  };

  return axios.all([
    getPreviousParagraphs({
      publicationId,
      paraId,
      paragraphsCount,
      language,
      highlight,
      options: nextOptions,
      searchText,
    }),
    getNextParagraphs({
      publicationId,
      paraId,
      paragraphsCount,
      language,
      highlight,
      options: nextOptions,
      searchText,
    }),
  ]);
}

/**
 * Retrieves previous paragraphs relative to a given paragraph.
 *
 * @param {Object} params - Parameters for the request.
 * @param {string} params.publicationId - ID of the publication.
 * @param {string} params.paraId - ID of the current paragraph.
 * @param {number} params.paragraphsCount - Number of paragraphs to retrieve.
 * @param {string} params.language - Language of the content.
 * @param {Object} params.highlight - Highlight options.
 * @param {Object} params.options - Additional options.
 * @param {string} params.searchText - Text to search for.
 * @returns {Promise} Promise resolving to previous paragraphs data.
 *
 * Trace:
  - ElasticSearch.js: getParagraphs()
    (see above info for getParagraphsForEncumbered)
  - src/services/Search/FullTextSearchService.js: getPreviousParagraphs()
    - src/store/modules/ParagraphsStore.js: getPrevParagraphs()
      - ParagraphsStore.js: actions.getPrevParagraphs()
        - ParagraphsStore.js: actions.performParagraphsSearchPrev()
          - src/containers/paragraphs/Paragraphs.vue: methods.handleScrollToTop()
 *
 * This function is called to retrieve paragraphs that come before the current paragraph.
 * It is called in all the same circumstances as getParagraphs above, as well as when:
 *
 * - A user scrolls up in the paragraph view and reaches the top of the loaded content
 */
function getPreviousParagraphs({
  publicationId,
  paraId,
  paragraphsCount,
  language,
  highlight,
  options,
  searchText,
}) {
  const rightRange = {
    start: 0,
    end: -_applyGapCorrection(paragraphsCount),
  };
  return _getParaObjByRange(
    publicationId,
    paraId,
    rightRange,
    language,
    highlight,
    paragraphsCount,
    searchText
  ).then(paraObj => {
    if (!paraObj) {
      return;
    }
    let end = paraObj.sortedIds?.length;
    let start = end - paragraphsCount;

    if (!options?.includeCurrentPara) {
      end -= 1;
      start -= 1;
    }
    start = Math.max(start, 0);
    return _createSentencesObj(paraObj, start, end);
  });
}

/**
 * Applies a correction factor to the number of paragraphs.
 *
 * @param {number} paragraphsCount - Number of paragraphs.
 * @returns {number} Corrected number of paragraphs.
 * @private
 */
function _applyGapCorrection(paragraphsCount) {
  return Math.round(paragraphsCount * CORRECTION_PARA_GAPS);
}

/**
 * Retrieves paragraph object by range.
 *
 * @param {string} publicationId - ID of the publication.
 * @param {string} paraId - ID of the paragraph.
 * @param {Object} directionRange - Range object for direction.
 * @param {string} lang - Language of the content.
 * @param {Object} parsedQuery - Options for highlighting.
 * @param {number} paragraphsCount - Number of paragraphs to retrieve.
 * @param {string} searchText - Text to search within paragraphs.
 * @returns {Promise} Promise resolving to paragraph object.
 * @private
 *
 * Used internally by getPreviousParagraphs and getNextParagraphs.
 */
function _getParaObjByRange(
  publicationId,
  paraId,
  directionRange,
  lang,
  parsedQuery
) {
  if (!paraId) {
    return Promise.reject(
      `Online search paraId ${paraId} for get pararagraph by directionRange ${JSON.stringify(
        directionRange
      )} is not defined for book ${publicationId}`
    );
  }
  const range = _applyDirection(paraId, directionRange);
  const paraQuery = {
    size: 500,
    query: {
      bool: {
        must: [
          {
            match: {
              publicationId: {
                query: publicationId,
              },
            },
          },
          {
            regexp: {
              paraId: {
                value: 'para_<' + range.start + '-' + range.end + '>',
              },
            },
          },
        ],
      },
    },
  };
  _addHighlight(paraQuery, parsedQuery.mainQuery);
  const reqOptions = {
    nonCanceledRequest: true,
    requestType: RequestEnum.REQUEST_FOR_CONTENT_INDEXES,
  };
  return request
    .searchReq(paraQuery, lang, reqOptions)
    .then(_rawDocs => {
      if (!_rawDocs) {
        return;
      }
      const paraMap = parseResponse.parseParagraphsToMap({
        response: _rawDocs,
      });
      return _createParaStructureFromParaMap(paraMap);
    })
    .catch(error => {
      error.message += ` (getByRange)`;
      throw error;
    });
}

/**
 * Applies direction to the paragraph range.
 *
 * @param {string} paraId - ID of the current paragraph.
 * @param {Object} directionRange - Range object for direction.
 * @returns {Object} Adjusted range object.
 * @private
 */
function _applyDirection(paraId, directionRange) {
  const paraNum = _getParaNum(paraId);
  const start = directionRange.start + paraNum;
  const end = directionRange.end + paraNum;
  return {
    start: Math.max(start, 0),
    end: Math.max(end, 0),
  };
}

/**
 * Creates a sentences object from a paragraph object.
 *
 * @param {Object} paraObj - Paragraph object containing paraMap and sortedIds.
 * @param {number} start - Starting index for slicing sortedIds.
 * @param {number} end - Ending index for slicing sortedIds.
 * @returns {Object} Sentences object with paraMap and sortedIds.
 * @private
 */
function _createSentencesObj(paraObj, start, end) {
  const paraMap = {};
  const sortedIds = paraObj.sortedIds.slice(start, end) || [];
  sortedIds.forEach(paraId => {
    paraMap[paraId] = paraObj.paraMap[paraId];
  });
  return {
    paraMap: paraMap,
    sortedIds: sortedIds,
  };
}

/**
 * Retrieves next paragraphs for a given publication and paragraph.
 *
 * @param {Object} params - Parameters for retrieving next paragraphs.
 * @param {string} params.publicationId - ID of the publication.
 * @param {string} params.paraId - ID of the current paragraph.
 * @param {number} params.paragraphsCount - Number of paragraphs to retrieve.
 * @param {string} params.language - Language of the content.
 * @param {Object} params.highlight - Highlight options for the paragraphs.
 * @param {Object} params.options - Additional options for the request.
 * @param {string} params.searchText - Text to search within the paragraphs.
 * @returns {Promise} Promise resolving to next paragraphs data.
 *
 * Trace:
  - ElasticSearch.js: getParagraphs()
    (see above info for getParagraphsForEncumbered)
  - src/services/Search/FullTextSearchService.js: getNextParagraphs()
    - src/store/modules/ParagraphsStore.js: getNextParagraphs()
      - ParagraphsStore.js: actions.getNextParagraphs()
        - ParagraphsStore.js: actions.performParagraphsSearchNext()
          - src/containers/paragraphs/Paragraphs.vue: methods.handleScrollToBottom()
 *
 * This function is called to retrieve paragraphs that come after the current paragraph.
 * It is called in all the same circumstances as getParagraphs above, as well as when:
 *
 * - A user scrolls down in the paragraph view and reaches the bottom of the loaded content
 */
function getNextParagraphs({
  publicationId,
  paraId,
  paragraphsCount,
  language,
  highlight,
  options,
  searchText,
}) {
  const leftRange = {
    start: _applyGapCorrection(paragraphsCount),
    end: 0,
  };
  return _getParaObjByRange(
    publicationId,
    paraId,
    leftRange,
    language,
    highlight,
    paragraphsCount,
    searchText
  ).then(paraObj => {
    if (!paraObj) {
      return;
    }
    let start = 0;
    let end = paragraphsCount;

    if (options && !options.includeCurrentPara) {
      start += 1;
      end += 1;
    }
    return _createSentencesObj(paraObj, start, end);
  });
}

/**
 * Retrieves a list of navigation items for a given publication and query.
 *
 * @param {Object} params - Parameters for retrieving the navigation list.
 * @param {Object} params.parsedQuery - Parsed query object.
 * @param {string} params.publicationId - ID of the publication.
 * @param {string} params.language - Language of the content.
 * @param {number} params.itemsCount - Number of items to retrieve.
 * @returns {Promise} Promise resolving to navigation list data.
 *
 * Trace:
  - src/store/modules/NavigationStore.js: getNavigation()
    - NavigationStore.js: actions.getNavigation()
      - NavigationStore.js: actions.performNavigationSearch()
        - src/store/modules/HitsStore.js: actions.setActiveHit()
          - HitsStore.js: actions.fillHitsStore()
          - HitsStore.js: actions.resetHitsStore()
          - src/containers/searchhits/SearchHits.vue: methods.$_setActiveHit()
            - SearchHits.vue: watch.mainPopupOpened.handler()
              (called as soon as the Popup component is opened)
            - SearchHits.vue: methods.$_processActivateHit()
              - SearchHits.vue: methods.handleHitItemEvent()
                - src/containers/searchhits/SearchHitItem.vue: methods.activateHit()
                  (called on SearchHitItem click event)
      - src/store/modules/SearchStore.js: performSearch()
        (see note above)
 *
 * This function can be traced back to whenever the "getNavigation" action is dispatched.
 * Its purpose is to retrieve a list of navigation items for a single publication,
 * and these are used in the title bar of the Content pane.
 * It may be called whenever a search is executed, or when the user:
 *
 * - clicks a SearchHitItem that is not in the same book as the active hit
 *
 * NOTE: if this is not properly populated, there will be a nasty error thrown
 * trying to call getDocById() with no docId. @see NavigationStore.js:setHitByNavigationIndex().
 * This situation can arise if you're using a different query bewteen getHits and this function.
 */
function getNavigateList({ parsedQuery, publicationId, language, itemsCount }) {
  // console.log('getNavigateList', { publicationId, language, itemsCount });
  const from = 0;
  const mainQuery = JSON.parse(JSON.stringify(parsedQuery.mainQuery));
  const bookSentencesListQuery = {
    ...mainQuery,
    from,
    size: itemsCount,
    collapse: undefined,
    highlight: undefined,
    _source: ['docId', 'paraId'],
  };
  bookSentencesListQuery.query.bool.filter.push({
    term: {
      publicationId,
    },
  });
  bookSentencesListQuery.sort = ['_score', 'locator.keyword'];
  return request
    .searchReq(bookSentencesListQuery, language, {
      requestType: RequestEnum.REQUEST_FOR_CONTENT_INDEXES,
    })
    .catch(error => {
      error.message += ' (getNavigateList)';
      throw error;
    });
}

/**
 * Retrieves hits based on the parsed query.
 *
 * @param {Object} params - Parameters for the search.
 * @param {number} params.startIndex - Starting index for the search results.
 * @param {string} params.publicationId - ID of the publication to search in (optional).
 * @param {Object} params.parsedQuery - Parsed query object.
 * @param {number} params.sentencesCount - Number of sentences to retrieve.
 * @param {boolean} params.collapsed - Whether to collapse results.
 * @param {string} params.language - Language of the content.
 * @returns {Promise} Promise resolving to search hits.
 *
 * Trace:
  - src/containers/searchhits/SearchHits.vue: computed.hits
    (called whenever the search hits are displayed in the virtual list)
  - src/services/Search/FullTextSearch.js: getHits()
    - src/store/modules/HitsStore.js: getHits()
      - HitsStore.js: actions.getHits()
        - HitsStore.js: actions.performSearchMoreHits()
          - src/containers/searchhits/SearchHits.vue: methods.$_loadMore()
            - SearchHits.vue: methods.onScroll()
              (called whenever the user is scrolling through the search hits)
        - HitsStore.js: actions.performSearchMoreHitsByPublication()
          - src/containers/searchhits/SearchHits.vue: methods.$_updateScrollAfterExpand()
            - SearchHits.vue: methods.handleHitItemEvent()
              (called when the user expands a search item)
          - SearchHits.vue: methods.$_loadMoreByExpandedPublication()
            - SearchHits.vue: methods.onScroll()
              (called whenever the user is scrolling through the search hits)
        - src/store/modules/SearchStore.js: performSearch()
          (see note above)
 *
 * This is the main search function that retrieves a list of search hits for the main query.
 * It is called whenever a search is executed, or when the user:
 *
 * - scrolls through the search hits with an item expanded
 * - expands a search item
 */
function getHits(params) {
  // console.log('getHits', params);
  const from = Math.max(params.startIndex, 0);
  const publicationId =
    params.publicationId ??
    (params.parsedQuery.filter.length ? params.parsedQuery.filter : undefined);
  const sentenceListQuery = queryBuilder(params.parsedQuery.queryText, {
    from,
    size: params.sentencesCount,
    publicationId,
    collapse: params.collapsed ? INNER_HITS_COUNT : 0,
    getBookCount: true,
  });
  if (!params.collapsed) {
    sentenceListQuery.sort = ['_score', 'locator.keyword'];
  }
  const options = {
    requestType: RequestEnum.REQUEST_FOR_CONTENT_INDEXES,
  };
  return request
    .searchReq(sentenceListQuery, params.language, options)
    .catch(error => {
      error.message += ' (getHits)';
      throw error;
    });
}

/**
 * Retrieves IDs of publications found in the search results.
 *
 * @param {Object} params - Parameters for retrieving found publication IDs.
 * @param {Object} params.parsedQuery - Parsed query object.
 * @param {string} params.language - Language of the content.
 * @returns {Promise} Promise resolving to found publication IDs.
 *
 * Trace:
  - src/services/Search/FullTextSearchService.js: getFoundPublicationIds()
    - src/store/modules/FilterStore.js: getFoundPublicationIds()
      - src/store/modules/FilterStore.js: actions.getFoundFilteredPublicationIds()
        - src/store/modules/SearchStore.js: getFoundFilterPublicationIds()
          - SearchStore.js: performSearch()
            (see note above)
 *
 * This function retrieves a list of publication IDs in which the search results were found.
 * That data is used to populate the hit counts in the Filters pane.
 * The function is called whenever a search is executed.
 */
function getFoundPublicationIds({ parsedQuery, language }) {
  const fullQuery = {
    query: {
      bool: {
        must: parsedQuery.mainQuery.query.bool.must,
      },
    },
    from: 0,
    size: 0,
    aggs: {
      publicationIds: {
        terms: {
          field: 'publicationId',
          size: 10000,
        },
      },
    },
  };
  // console.log('getFoundPublicationIds', { fullQuery, language });
  return request
    .searchReq(fullQuery, language, {
      requestType: RequestEnum.REQUEST_FOR_CONTENT_INDEXES,
    })
    .catch(error => {
      error.message += ' (getFoundPublicationIds)';
      throw error;
    });
}

/**
 * Retrieves a document by its ID.
 *
 * @param {Object} params - Parameters for retrieving the document.
 * @param {string} params.docId - ID of the document to retrieve.
 * @param {string} params.language - Language of the content.
 * @returns {Promise} Promise resolving to document data.
 *
 * Trace:
  - src/services/Search/FullTextSearchService.js: getDocById()
    - src/SearchWidget.vue: $_getDocByDocId()
      (never called)
    - src/store/modules/HitsStore.js: getHitById()
      - HitsStore.js: actions.getHitById()
        - HitsStore.js: actions.setActiveHitById()
          - src/containers/searchits/SearchHits.vue: methods.$_setActiveHitById()
            (never called)
          - src/store/modules/NavigationStore.js: actions.setHitByNavigationIndex()
            - src/containers/navigation/Navigation.vue: methods.$_navigate()
              - Navigation.vue: methods.navigateForward()
                (called when the user clicks the down chevron)
              - Navigation.vue: methods.navigateBack()
                (called when the user clicks the up chevron)
            - src/store/modules/SearchStore.js: performSearch()
              (see note above)
 *
 * This function keeps the active hit in the Results pane in sync with the selection
 * when the user uses the navigation buttons.
 * It is called whenever the search is executed, or when the user:
 *
 * - clicks the down chevron in the Content pane header
 * - clicks the up chevron in the Content pane header
 */
function getDocById({ docId, language }) {
  // console.log('getDocById', { docId, language });
  const docIdQuery = _createExactParagraphQuery(docId);
  const options = {
    requestType: RequestEnum.REQUEST_FOR_CONTENT_INDEXES,
  };
  return request
    .searchReq(docIdQuery, language, options)
    .then(function(_rawDoc) {
      return parseResponse.parseSingleHitResponse({ response: _rawDoc });
    })
    .catch(error => {
      error.message += ' (getDocById)';
      // throw error; // IF YOU THROW HERE, ERRORS SHOW ON CLIENT.
      // I think this may be a race condition between a request that results in an error
      // and a request that is canceled by the user, but it's not a useful error anyway.
    });
}

/**
 *
 * @param {Object} params - Parameters for retrieving filter data.
 * @param {string} params.language - Language of the content.
 * @returns {Promise} Promise resolving to filter data.
 *
 * Trace:
  - src/services/Search/FullTextSearchService.js: getFilter()
    - src/store/modules/FilterStore.js: getFilter()
      - FilterStore.js: actions.getFilter()
        - FilterStore.js: actions.performFilterSearchIfNeeded()
          - src/SearchWidget.vue: methods.init()
          - src/SearchWidget.vue: watch.language()
          - src/containers/languages/Languages.vue: methods.changeSearchLanguage()
 *
 * This function builds the entire Filter pane.
 * It is called whenever the base component is loaded or when the language changes.
 *
 * TODO: replace the runtime mappings with indexed fields
 */
function getFilter({ language }) {
  // console.log('getFilter', language);
  const filterQuery = {
    size: 10000,
    runtime_mappings: {
      parentWeight: {
        type: 'double',
        script: {
          lang: 'painless',
          source:
            "if (doc.containsKey('collectionWeight') && !doc['collectionWeight'].empty && doc['collectionWeight'].value > 0) { emit(doc.collectionWeight.value); } else { emit(doc.authorWeight.value); }",
        },
      },
      parentName: {
        type: 'keyword',
        script: {
          lang: 'painless',
          source:
            "if(doc.containsKey('collectionTitleNormalized') && !doc['collectionTitleNormalized'].empty) { emit(doc.collectionTitleNormalized.value)} else {emit(doc.authorNormalized.value)}",
        },
      },
    },
    sort: [
      { categoryNormalized: { order: 'asc' } },
      { parentWeight: { order: 'asc' } },
      { parentName: { order: 'asc' } },
      { bookWeight: { order: 'asc' } },
      // { titleNormalized: { order: 'asc' } },
      { title: { order: 'asc' } },
    ],
    query: {
      match_all: {},
    },
  };
  return request
    .searchReq(filterQuery, language, {
      requestType: RequestEnum.REQUEST_FOR_FILTER_INDEXES,
      nonCanceledRequest: true,
    })
    .catch(error => {
      error.message += ` (getFilter ${language})`;
      throw error;
    });
}

export default {
  getParagraphForEncumbered,
  getParagraphs,
  getPreviousParagraphs,
  getNextParagraphs,
  getNavigateList,
  getHits,
  getDocById,
  getFilter,
  getFoundPublicationIds,
};
